// src/components/FormResponses.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress,
  TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab, Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  '& .MuiTableCell-head': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontWeight: 'bold',
  },
  '& .MuiTableRow-root': {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(255, 255, 255, 0.03)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  },
}));

const TableChip = styled(Chip)(({ theme }) => ({
  margin: '2px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
}));


const DialogChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  padding: '4px 8px',
  margin: '2px',
  borderRadius: '16px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  wordBreak: 'break-word',
  maxWidth: '100%',
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  '& .MuiTypography-root': {
    wordBreak: 'break-word',
  },
}));

function FormResponses() {
  const { formId } = useParams();
  const [form, setForm] = useState(null);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { mode } = useTheme();
  const [filter, setFilter] = useState('');
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    fetchFormAndResponses();
  }, [formId]);

  useEffect(() => {
    console.log('Responses state updated:', responses);
  }, [responses]);

  useEffect(() => {
    console.log('Filter value changed:', filter);
  }, [filter]);

  const fetchFormAndResponses = async () => {
    try {
      setLoading(true);
      const formResponse = await api.get(`/forms/${formId}`);
      console.log('Form data:', formResponse.data);
      setForm(formResponse.data);
      const responsesResponse = await api.get(`/forms/${formId}/responses`);
      console.log('Responses data:', responsesResponse.data);
      setResponses(responsesResponse.data);
    } catch (error) {
      console.error('Error fetching form and responses:', error);
      setError('Failed to fetch form and responses');
    } finally {
      setLoading(false);
    }
  };

  const getChartData = (element) => {
    const data = responses.reduce((acc, response) => {
      const answer = response.answers.find(a => a.element_id === element.id);
      if (answer) {
        const value = answer.value;
        if (Array.isArray(value)) {
          value.forEach(v => {
            acc[v] = (acc[v] || 0) + 1;
          });
        } else {
          acc[value] = (acc[value] || 0) + 1;
        }
      }
      return acc;
    }, {});

    return {
      labels: Object.keys(data),
      datasets: [{
        data: Object.values(data),
        backgroundColor: [
          '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
          '#FF99E6', '#99FF99', '#FFFF99', '#FF9999', '#99FFFF', '#FF99FF'
        ],
      }]
    };
  };

  const exportToCSV = () => {
    const headers = ['Response ID', 'User IP', 'Submitted At', ...questionElements.map(e => e.content)];
    const csvContent = [
      headers.join(','),
      ...responses.map(response => [
        response.id,
        response.user_ip,
        new Date(response.submitted_at).toLocaleString(),
        ...questionElements.map(element => {
          const answer = response.answers.find(a => a.element_id === element.id);
          const value = answer ? answer.value : '';
          return `"${Array.isArray(value) ? value.join(', ') : value}"`;
        })
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `form_responses_${formId}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const truncateText = (text, maxLength = 50) => {
    const str = String(text);
    if (str.length <= maxLength) return str;
    return str.substr(0, maxLength) + "...";
  };

  console.log("Responses before filtering:", responses);

  const filteredResponses = responses.filter(response => {
    if (filter === '') return true; // Show all responses if no filter is applied
    return response.answers.some(answer => 
      String(answer.value).toLowerCase().includes(filter.toLowerCase())
    );
  });

  console.log("Filtered responses:", filteredResponses);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!form) return <Typography>Form not found</Typography>;

  const questionElements = form.elements.filter((e) => e.type === "question");
  console.log("Question elements:", questionElements);

  try {
    return (
      <Box sx={{ paddingTop: "20px" }}>
        <StyledPaper
          elevation={6}
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h4"
            sx={{ mb: 3, color: mode === "dark" ? "white" : "black" }}
          >
            Responses for: {form.title}
          </Typography>

          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              mb: 3,
            }}
          >
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
              centered
            >
              <Tab label="Responses" />
              <Tab label="Charts" />
            </Tabs>
          </Box>

          {tabValue === 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  width: "100%",
                }}
              >
                <TextField
                  label="Filter responses"
                  variant="outlined"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  sx={{ width: "300px" }}
                />
                <Button
                  onClick={exportToCSV}
                  variant="contained"
                  color="primary"
                >
                  Export to CSV
                </Button>
              </Box>

              <StyledTableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Submitted At</TableCell>
                      <TableCell>User IP</TableCell>
                      {questionElements.map((element) => (
                        <TableCell key={element.id}>
                          {element.content}
                        </TableCell>
                      ))}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredResponses.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={questionElements.length + 3}>
                          No responses found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredResponses.map((response) => (
                        <TableRow key={response.id}>
                          <TableCell>
                            {new Date(response.submitted_at).toLocaleString()}
                          </TableCell>
                          <TableCell>{response.user_ip}</TableCell>
                          {questionElements.map((element) => {
                            const answer = response.answers.find(
                              (a) => a.element_id === element.id
                            );
                            const value = answer ? answer.value : null;
                            return (
                              <TableCell key={element.id}>
                                {value
                                  ? Array.isArray(value)
                                    ? value.map((v, index) => (
                                        <TableChip
                                          key={index}
                                          label={truncateText(String(v), 20)}
                                        />
                                      ))
                                    : truncateText(String(value))
                                  : "N/A"}
                              </TableCell>
                            );
                          })}
                          <TableCell>
                            <Button
                              onClick={() => setSelectedResponse(response)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </>
          )}

          {tabValue === 1 && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {questionElements
                .filter((e) =>
                  ["checkbox", "multipleChoice", "dropdown"].includes(
                    e.questionType
                  )
                )
                .map((element) => (
                  <Box
                    key={element.id}
                    sx={{ width: "100%", height: "400px", mb: 4 }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {element.content}
                    </Typography>
                    <Pie
                      data={getChartData(element)}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: "right",
                          },
                        },
                      }}
                    />
                  </Box>
                ))}
            </Box>
          )}
        </StyledPaper>

        <Dialog
          open={!!selectedResponse}
          onClose={() => setSelectedResponse(null)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(10px)",
              boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
            },
          }}
        >
          <DialogTitle>Response Details</DialogTitle>
          <StyledDialogContent>
            {selectedResponse && (
              <Box>
                <Typography>
                  <strong>Submitted At:</strong>{" "}
                  {new Date(selectedResponse.submitted_at).toLocaleString()}
                </Typography>
                <Typography>
                  <strong>User IP:</strong> {selectedResponse.user_ip}
                </Typography>
                {questionElements.map((element) => {
                  const answer = selectedResponse.answers.find(a => a.element_id === element.id);
                  const value = answer ? answer.value : null;
                  return (
                    <Box key={element.id} sx={{ marginTop: 2 }}>
                      <Typography variant="subtitle1">
                        <strong>{element.content}</strong>
                      </Typography>
                      {value && Array.isArray(value) ? (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                          {value.map((v, index) => (
                            <DialogChip key={index}>{v}</DialogChip>
                          ))}
                        </Box>
                      ) : (
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                          {value ? value : "N/A"}
                        </Typography>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </StyledDialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedResponse(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  } catch (error) {
    console.error("Render error:", error);
    return <div>An error occurred while rendering the component</div>;
  }
}

export default FormResponses;