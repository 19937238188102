// src/components/FormPreview.js
import React from 'react';
import { Box, Typography, TextField, Radio, RadioGroup, FormControlLabel, Checkbox } from '@mui/material';

function FormPreview({ form }) {
  return (
    <Box sx={{ width: "100%", border: '1px solid #ccc', borderRadius: '4px', p: 2 }}>
      <Typography variant="h5">{form.title}</Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>{form.description}</Typography>
      {form.elements.map((element, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          {element.type === 'title' && (
            <Typography variant="h6">{element.content}</Typography>
          )}
          {element.type === 'text' && (
            <>
              <Typography>{element.content}</Typography>
              <TextField fullWidth disabled />
            </>
          )}
          {element.type === 'longText' && (
            <>
              <Typography>{element.content}</Typography>
              <TextField fullWidth multiline rows={4} disabled />
            </>
          )}
          {element.type === 'multipleChoice' && (
            <>
              <Typography>{element.content}</Typography>
              <RadioGroup>
                {element.options.map((option, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    value={option}
                    control={<Radio disabled />}
                    label={option}
                  />
                ))}
              </RadioGroup>
            </>
          )}
          {element.type === 'checkbox' && (
            <>
              <Typography>{element.content}</Typography>
              {element.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  control={<Checkbox disabled />}
                  label={option}
                />
              ))}
            </>
          )}
          {element.type === 'image' && (
            <Box sx={{ textAlign: 'center' }}>
              <img src={element.content} alt="Form content" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
          )}
          {element.type === 'pageBreak' && (
            <Box sx={{ borderTop: '1px dashed #ccc', my: 2 }}>
              <Typography variant="caption" sx={{ display: 'block', textAlign: 'center' }}>
                Page Break
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default FormPreview;
