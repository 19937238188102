// src/components/FormEditor.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Button, Paper, Tabs, Tab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';
import BasicInfo from './BasicInfo';
import QuestionEditor from './QuestionEditor';
import FormPreview from './FormPreview';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
}));

function FormEditor() {
  const { formId } = useParams();
  const [form, setForm] = useState({ id: '', title: '', description: '', elements: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const { mode } = useTheme();

  useEffect(() => {
    if (formId && formId !== 'new') {
      fetchForm();
    } else {
      setLoading(false);
    }
  }, [formId]);

  const fetchForm = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/forms/${formId}`);
      setForm(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching form:', error);
      setError('Failed to fetch form');
    } finally {
      setLoading(false);
    }
  };

  const saveForm = async () => {
    try {
      setLoading(true);
      const response = await api.put(`/forms/${formId}`, form);
      navigate(`/forms/${response.data.id}/edit`);
    } catch (error) {
      console.error('Error saving form:', error);
      setError('Failed to save form');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ paddingTop: "20px" }}>
      <StyledPaper
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h4" sx={{ mb: 3, color: mode === "dark" ? "white" : "black" }}>
          Edit Form
        </Typography>
        <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} sx={{ mb: 3 }}>
          <Tab label="Basic Info" />
          <Tab label="Questions" />
          <Tab label="Preview" />
        </Tabs>
        {tabValue === 0 && <BasicInfo form={form} setForm={setForm} />}
        {tabValue === 1 && <QuestionEditor form={form} setForm={setForm} />}
        {tabValue === 2 && <FormPreview form={form} />}
        <Button
          variant="contained"
          onClick={saveForm}
          disabled={loading}
          sx={{ mt: 3 }}
        >
          Update Form
        </Button>
      </StyledPaper>
    </Box>
  );
}

export default FormEditor;
