// src/components/NewFormPrompt.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, TextField, Button, Paper, Radio, RadioGroup, FormControlLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
}));

function NewFormPrompt() {
  const [idType, setIdType] = useState('random');
  const [customId, setCustomId] = useState('');
  const navigate = useNavigate();
  const { mode } = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/forms', {
        id: idType === 'custom' ? customId : undefined,
        title: 'New Form',
        description: '',
        elements: []
      });
      navigate(`/forms/${response.data.id}/edit`);
    } catch (error) {
      console.error('Error creating form:', error);
    }
  };

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <StyledPaper
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h4" sx={{ mb: 3, color: mode === 'dark' ? 'white' : 'black' }}>
          Create New Form
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <RadioGroup
            value={idType}
            onChange={(e) => setIdType(e.target.value)}
            sx={{ mb: 2 }}
          >
            <FormControlLabel value="random" control={<Radio />} label="Generate random ID" />
            <FormControlLabel value="custom" control={<Radio />} label="Use custom URL" />
          </RadioGroup>
          {idType === 'custom' && (
            <TextField
              fullWidth
              label="Custom ID"
              value={customId}
              onChange={(e) => setCustomId(e.target.value)}
              sx={{ mb: 2 }}
              helperText="Use a custom URL for your form (example: pel.bot/forms/my-form)"
            />
          )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
          >
            Create Form
          </Button>
        </form>
      </StyledPaper>
    </Box>
  );
}

export default NewFormPrompt;
