// src/components/BasicInfo.js
import React from 'react';
import { TextField } from '@mui/material';

function BasicInfo({ form, setForm }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
  };

  return (
    <>
      <TextField
        fullWidth
        label="Form Title"
        name="title"
        value={form.title}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Form Description"
        name="description"
        value={form.description}
        onChange={handleInputChange}
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />
    </>
  );
}

export default BasicInfo;
