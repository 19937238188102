// src/components/StatusManager.js

import React, { useState, useEffect } from 'react';
import { 
  List, 
  ListItem,
  Typography, 
  Box, 
  Paper, 
  CircularProgress, 
  Button, 
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Card,
  CardContent,
  Tooltip
} from '@mui/material';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';
import AddIcon from '@mui/icons-material/Add';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import InfoIcon from '@mui/icons-material/Info';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
});

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
}));

const AnimatedListItem = styled(motion(ListItem))(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  }
}));


const VoteBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '16px',
});

const VoteCount = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1rem',
  margin: '0 8px',
  minWidth: '20px',
  textAlign: 'center',
}));

const InfoCard = styled(Card)(({ theme }) => ({
  marginTop: '20px',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 16px 0 rgba(31, 38, 135, 0.37)',
}));

function StatusManager() {
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newStatus, setNewStatus] = useState({ text: '', type: 'Custom', presence: 'online', duration: 30 });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { mode } = useTheme();

  useEffect(() => {
    fetchStatuses();
  }, []);

  const fetchStatuses = async () => {
    try {
      setLoading(true);
      const response = await api.get('/status');
      setStatuses(response.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching statuses:', error);
      setError('Failed to fetch statuses. Please try again later.');
      setLoading(false);
    }
  };

  const handleNewStatusChange = (event) => {
    setNewStatus({ ...newStatus, [event.target.name]: event.target.value });
  };

  const handleSubmitNewStatus = async () => {
    try {
      await api.post('/status/submit', newStatus);
      setNewStatus({ text: '', type: 'Custom', presence: 'online', duration: 30 });
      setDialogOpen(false);
      fetchStatuses();
    } catch (error) {
      console.error('Error submitting new status:', error);
      setError('Failed to submit new status. Please try again later.');
    }
  };

  const handleVote = async (id, isUpvote) => {
    try {
      await api.post(`/status/${id}/${isUpvote ? 'upvote' : 'downvote'}`);
      fetchStatuses();
    } catch (error) {
      console.error('Error voting on status:', error);
      setError('Failed to vote on status. Please try again later.');
    }
  };

  const sortedStatuses = statuses.length > 0 ? [...statuses].sort((a, b) => (b.weight || 0) - (a.weight || 0)) : [];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <FullscreenBox>
      <StyledPaper 
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" sx={{ color: mode === 'dark' ? 'white' : 'black', mb: 3 }}>Statuses</Typography>
        </motion.div>
        <Box display="flex" justifyContent="flex-end" width="100%" mb={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setDialogOpen(true)}
            sx={{ 
              background: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              color: mode === 'dark' ? 'white' : 'black',
              '&:hover': {
                background: mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
              }
            }}
          >
            Submit New Status
          </Button>
        </Box>
        {loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CircularProgress sx={{ color: mode === 'dark' ? 'white' : 'black' }} />
          </motion.div>
        ) : error ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>{error}</Typography>
          </motion.div>
        ) : (
          <AnimatePresence mode="wait">
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              style={{ width: '100%' }}
            >
              <List>
                {sortedStatuses.map((status) => (
                  <AnimatedListItem
                    key={status.id}
                    variants={itemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Box>
                      <Typography variant="h6" sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
                        {status.type !== 'Custom' ? `${status.type} ${status.text}` : status.text}
                      </Typography>
                    </Box>
                    <VoteBox>
                      <IconButton onClick={() => handleVote(status.id, true)}>
                        <ThumbUpIcon sx={{ color: mode === 'dark' ? 'white' : 'black' }} />
                      </IconButton>
                      <VoteCount sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
                        {status.weight || 0}
                      </VoteCount>
                      <IconButton onClick={() => handleVote(status.id, false)}>
                        <ThumbDownIcon sx={{ color: mode === 'dark' ? 'white' : 'black' }} />
                      </IconButton>
                    </VoteBox>
                  </AnimatedListItem>
                ))}
              </List>
            </motion.div>
          </AnimatePresence>
        )}
        <InfoCard>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              How it works
              <Tooltip title="Info">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="body2">
              Submit a status for pelbot with the button up the top, submitted statuses are verified by an admin before being shown on the bot. You can vote on statuses to increase their likelyhood of being displayed.
            </Typography>
          </CardContent>
        </InfoCard>
      </StyledPaper>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Submit New Status</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="text"
            label="Status Text"
            type="text"
            fullWidth
            variant="outlined"
            value={newStatus.text}
            onChange={handleNewStatusChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmitNewStatus}>Add Status</Button>
        </DialogActions>
      </Dialog>
    </FullscreenBox>
  );
}

export default StatusManager;