// src/components/FormManager.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Typography, Button, Paper, List, ListItem, ListItemText, IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PollIcon from '@mui/icons-material/Poll';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
}));

function FormManager() {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { mode } = useTheme();

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    console.log('Fetching forms');
    try {
      setLoading(true);
      const response = await api.get('/forms');
      console.log('Fetched forms:', response.data);
      setForms(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching forms:', error);
      setError('Failed to fetch forms');
    } finally {
      setLoading(false);
    }
  };

  const deleteForm = async (formId) => {
    console.log('Deleting form with ID:', formId);
    try {
      await api.delete(`/forms/${formId}`);
      setForms(prevForms => prevForms.filter(form => form.id !== formId));
    } catch (error) {
      console.error('Error deleting form:', error);
      setError('Failed to delete form');
    }
  };

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <StyledPaper>
        <Typography variant="h4" sx={{ mb: 3, color: mode === 'dark' ? 'white' : 'black' }}>
          Form Manager
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/forms/new')}
          sx={{ mb: 3 }}
        >
          Create New Form
        </Button>
        {loading && <Typography>Loading forms...</Typography>}
        {error && <Typography color="error">{error}</Typography>}
        {!loading && !error && (
          <List sx={{ width: '100%' }}>
            {forms.length === 0 ? (
              <Typography>no forms :c</Typography>
            ) : (
              forms.map((form) => (
                <ListItem
                  key={form.id}
                  secondaryAction={
                    <Box>
                      <IconButton edge="end" aria-label="edit" onClick={() => navigate(`/forms/${form.id}/edit`)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="view" onClick={() => navigate(`/forms/${form.id}`)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="responses" onClick={() => navigate(`/forms/${form.id}/responses`)}>
                        <PollIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => deleteForm(form.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <ListItemText
                    primary={form.title}
                    secondary={form.description}
                    sx={{ color: mode === 'dark' ? 'white' : 'black' }}
                  />
                </ListItem>
              ))
            )}
          </List>
        )}
      </StyledPaper>
    </Box>
  );
}

export default FormManager;